<template>
  <div id="facebook" v-cloak>
    <!-- 顶部 -->
    <div id="header-part">
      <div class="header-part-inner">
        <div class="header-left line-block-c">
          <img :src="logoImg" />
          <div class="header-search">
            <button type="button" disabled></button>
            <input placeholder="搜索 Facebook" disabled />
          </div>
        </div>
        <ul class="header-center line-block-c">
          <li>
            <a href="javascript:;" class="header-nav-item one current"></a>
          </li>
          <!-- <li><a href="javascript:;" class="header-nav-item two"></a></li>
                    <li><a href="javascript:;" class="header-nav-item three"></a></li> -->
        </ul>
        <!-- 个人信息 目前隐藏 -->
        <div class="header-right" style="display: none">
          <div class="header-avatar-c">
            我是用户名
            <img :src="txImg" class="header-avatar" />
            <div class="header-avatar-dropdown">
              <div class="header-avatar-homepage">
                <img :src="txImg" />
                <h3>xxx</h3>
                <a href="javascript:;">查看你的个人主页</a>
              </div>
              <div class="hr"></div>
              <ul>
                <li>
                  <a href="javascript:;" class="logout">退出</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 主体 -->
    <div class="main-content">
      <div class="main-content-inner">
        <div id="main-part">
          <div class="main-left">
            <ul>
              <li>
                <a href="javascript:;"
                  ><i class="user"></i>{{ userInfo.name }}</a
                >
              </li>
            </ul>
          </div>
          <div class="main-center">
            <div class="center-send-fb main-center-card">
              <div class="send-fb-c">
                <div class="send-fb-text">
                  <img :src="txImg" alt="" class="send-fb-avatar" />
                  <div class="send-fb-content">
                    <button class="js-open-create" @click="showCreated">
                      {{ userInfo.name }}，分享你的新鲜事吧！
                    </button>
                  </div>
                </div>
                <div class="hr"></div>
                <div class="send-fb-other">
                  <ul class="inline-block-c">
                    <li>
                      <button class="js-open-create" @click="showCreated">
                        <i class="pic"></i>照片
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="fb-list-c" v-if="dataList.length > 0">
              <ul class="fb-list">
                <template v-for="item in dataList">
                  <li class="fb-item main-center-card" :key="item.article_id">
                    <div class="fb-sender">
                      <img :src="txImg" alt="" class="fb-sender-avatar" />
                      <h3>{{ item.name }}</h3>
                      <p>{{ item.add_time }} · <i></i></p>
                      <div class="fb-oper">
                        <div class="fb-oper-arrow-c">...</div>
                        <div class="fb-oper-dropdown">
                          <div class="fb-oper-top">
                            <a
                              href="javascript:;"
                              @click="deleteItem(item)"
                              class="fb-oper-item js-del-fb-btn"
                              ><i></i>移至垃圾箱</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="fb-content">
                      <div
                        class="fb-txt with-img"
                        v-if="item.image_arr && item.image_arr.length > 0"
                      >
                        {{ item.content }}
                      </div>
                      <div class="fb-txt" v-else>{{ item.content }}</div>
                      <div
                        class="fb-pic-c"
                        v-if="item.image_arr && item.image_arr.length > 0"
                      >
                        <div class="fb-image-box">
                          <ul :class="`num${item.image_arr.length}`">
                            <li
                              v-for="(it, index) in item.image_arr"
                              :key="index"
                              @click="showImg(it)"
                            >
                              <img :src="it" />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <p class="fb-comment-count">
                        <span id="count_{$avo.id}">{{
                          item.content_count
                        }}</span
                        >条评论
                      </p>
                    </div>
                    <div class="fb-bottom-btns">
                      <button class="fb-comment-btn" @click="showComment(item)">
                        评论
                      </button>
                    </div>
                    <div
                      class="fb-comment-c"
                      v-if="activeCommentID === item.article_id"
                    >
                      <div class="comment-box">
                        <img :src="txImg" class="comment-avatar" />
                        <div class="comment-textarea">
                          <input
                            placeholder="写评论...（请使用英文评论）"
                            :article_id="item.article_id"
                            data-pid="0"
                            class="js-submit-comment-input"
                          />
                          <p class="submit-comment-tips">按 Enter 键发布。</p>
                        </div>
                      </div>
                      <ul class="comment-list-lv1">
                        <li
                          class="comment-empty"
                          v-if="
                            item.content_list && item.content_list.length < 1
                          "
                        >
                          暂没有评论...
                        </li>
                        <template v-else>
                          <li
                            class="comment-item"
                            v-for="it in item.content_list"
                            :key="it.article_content_id"
                          >
                            <img
                              :src="txImg"
                              alt=""
                              class="comment-item-avatar"
                            />
                            <div class="comment-item-inner">
                              <div class="comment-item-top">
                                <span class="comment-sender">{{
                                  it.name
                                }}</span>
                                <div>{{ it.content }}</div>
                              </div>
                              <div class="comment-item-bottom">
                                <div class="comment-datetime">
                                  <button
                                    class="replay-comment-btn"
                                    :data-id="it.article_content_id"
                                    :data-pid="it.level"
                                  >
                                    回复
                                  </button>
                                  ·
                                  {{ it.add_time }}
                                </div>
                              </div>
                              <ul class="comment-list-lv2" v-if="it.children">
                                <li
                                  class="comment-item"
                                  v-for="t in it.children"
                                  :key="t.article_content_id"
                                >
                                  <img
                                    :src="txImg"
                                    alt=""
                                    class="comment-item-avatar"
                                  />
                                  <div class="comment-item-inner">
                                    <div class="comment-item-top">
                                      <span class="comment-sender">{{
                                        t.name
                                      }}</span>
                                      对
                                      <span class="comment-sender">{{
                                        t.to_name
                                      }}</span>
                                      <div>{{ t.content }}</div>
                                    </div>
                                    <div class="comment-item-bottom">
                                      <div class="comment-datetime">
                                        <button
                                          class="replay-comment-btn"
                                          :data-id="t.article_content_id"
                                          :data-pid="t.level"
                                        >
                                          回复
                                        </button>
                                        ·
                                        {{ t.add_time }}
                                      </div>
                                    </div>
                                    <ul
                                      class="comment-list-lv2"
                                      v-if="t.children"
                                    >
                                      <li
                                        class="comment-item"
                                        v-for="i in t.children"
                                        :key="i.article_content_id"
                                      >
                                        <img
                                          :src="txImg"
                                          alt=""
                                          class="comment-item-avatar"
                                        />
                                        <div class="comment-item-inner">
                                          <div class="comment-item-top">
                                            <span class="comment-sender">{{
                                              i.name
                                            }}</span>
                                            对
                                            <span class="comment-sender">{{
                                              i.to_name
                                            }}</span>
                                            <div>{{ i.content }}</div>
                                          </div>
                                          <div class="comment-item-bottom">
                                            <div class="comment-datetime">
                                              <!-- <button class="replay-comment-btn" :data-id="i.article_content_id" :data-pid="i.level">回复</button> -->
                                              ·
                                              {{ i.add_time }}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
            <div class="main-center-card no-more-post" v-else>
              <h3>没有更多的帖子了</h3>
              <!-- <p>添加更多好友以便在动态消息中显示更多帖子。</p>
                            <a href="javascript:;">搜索好友</a> -->
            </div>
          </div>
          <div class="main-right">
            <!-- <h2>群聊</h2> -->
          </div>
          <div class="clear-float"></div>
          <!-- 创建帖子 -->
          <div class="create-fb" v-if="addVisible">
            <div class="create-fb-inner">
              <div class="create-fb-header">
                <h2>创建帖子</h2>
                <button
                  class="create-fb-close"
                  @click="addVisible = false"
                ></button>
              </div>
              <div class="create-fb-content">
                <div class="send-fb-c">
                  <div class="create-fb-sender">
                    <img :src="txImg" />
                    <h4>{{ userInfo.name }}</h4>
                    <div class="open"><i></i>公开</div>
                  </div>
                  <div class="create-fb-text">
                    <textarea
                      v-model="addText"
                      :placeholder="
                        userInfo.name +
                        '分享你的新鲜事吧！（内容请使用英文描述）'
                      "
                    ></textarea>
                  </div>
                  <div class="fb-image-box" v-if="upFiles.length > 0">
                    <ul :class="'num' + upFiles.length">
                      <li v-for="(item, i) in upFiles" :key="i">
                        <img :src="item" alt="" />
                      </li>
                    </ul>
                    <!-- <div class="more-than-5">+0</div> -->
                    <label class="add-img-on-img" v-if="upFiles.length < 5">
                      添加照片
                      <input
                        type="file"
                        ref="picUrlInput"
                        accept="image/jpeg,image/jpg,image/png,image/gif"
                        @change="onloadFn"
                        class="js-add-img-input"
                      />
                    </label>
                    <button
                      class="create-fb-clear-img"
                      @click="clearUpImg"
                    ></button>
                  </div>
                </div>
                <div class="add-more-fb-content">
                  <h4>添加更多内容</h4>
                  <label class="send-fb-add-img" v-if="upFiles.length < 5">
                    <input
                      type="file"
                      accept="image/jpeg,image/jpg,image/png,image/gif"
                      @change="onloadFn"
                      class="js-add-img-input"
                    />
                  </label>
                </div>
              </div>
              <div class="create-fb-footer">
                <button class="send-fb-btn" @click="submit">发帖</button>
              </div>
            </div>
          </div>

          <div class="del-fb" v-if="deleteVisble">
            <div class="del-fb-inner">
              <div class="del-fb-header">
                <h2>删除帖子</h2>
                <button
                  class="del-fb-close"
                  @click="deleteVisble = false"
                ></button>
              </div>
              <div class="del-fb-content">
                <p>确定要删除这条帖子吗？</p>
              </div>
              <div class="del-fb-footer">
                <button class="cancel" @click="deleteVisble = false">
                  取消
                </button>
                <button class="confirm" @click="sureDelete">删除</button>
              </div>
            </div>
          </div>

          <!-- 预览 -->
          <div class="preimg" v-if="preShow" @click="preShow = false">
            <div class="imgcontent">
              <img :src="preImg" alt="" onerror="onerror=null;" />
            </div>
          </div>
          <div class="page-loading" v-if="loading">
            <img :src="loadingImg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getArticleList,
  submitArticle,
  delArticle,
  replyContent,
} from "@/api/shopGoods.js";
import { uploadFiles } from "@/api/common.js";
import $ from "jquery";
export default {
  data() {
    return {
      newcomment: "", // 评论
      userInfo: {
        name: "",
      },
      dataList: [], // 帖子列表
      loading: false,
      preShow: false,
      preImg: "",
      addVisible: false,
      addText: "",
      subText: "", // 回复他们评论
      upFiles: [], // 上传回显图片列表
      upFileNames: [], // 上传图片返回文件名列表
      upFileNamesID: [], // 上传图片返回文件名列表ID
      commentSubmitting: false,
      deleteVisble: false,
      deleteForm: {}, // 删除帖子
      activeCommentID: "", // 当前评论的帖子ID
      goods_id: "", // 绑定商品的id
      logoImg: require("@/views/seller/shop/goods/facebook/images/common/logo.png"),
      txImg: require("@/views/seller/shop/goods/facebook/images/common/i-avatar-d.png"),
      loadingImg: require("@/views/seller/shop/goods/facebook/images/common/loading-1.gif"),
    };
  },
  created() {
    // let query = this.getAllUrlParams(location.href);
    // if (query.goods_id) {
    //   this.goods_id = query.goods_id;
    // }
    if (sessionStorage.GOODSID) {
      this.goods_id = sessionStorage.GOODSID;
    }
    this.getArticleList();
    this.getUserInfo();
  },
  mounted() {
    var that = this;
    $("#main-part")
      .on("click", ".fb-comment-btn", function () {
        $(this).parent().next().toggleClass("show");
      })
      .on("click", ".replay-comment-btn", function () {
        var $self = $(this);

        var $commentItemBottom = $self.closest(".comment-item-bottom");
        var $commentBox = $commentItemBottom.siblings(".comment-box");

        if ($commentBox.length === 0) {
          $commentItemBottom.after(
            "" +
              '<div class="comment-box">' +
              '<img src="' +
              that.txImg +
              '" class="comment-avatar "/>' +
              '<div class="comment-textarea">' +
              '<input placeholder="回复...（请使用英文评论）" data-id="' +
              $self.attr("data-id") +
              '" data-pid="' +
              $self.attr("data-pid") +
              '" class="js-submit-comment-input" />' +
              '<p class="submit-comment-tips">按 Enter 键发布。</p>' +
              "</div>" +
              "</div>" +
              ""
          );
        } else {
          $commentBox.toggleClass("hide");
        }
      })
      .on("keypress", ".js-submit-comment-input", function (e) {
        var $input;
        if (e.keyCode === 13) {
          $input = $(this);
          var content = $input.val().trim();
          if (content) {
            if (content.length > 1000) {
              that.message("超出1000字（当前" + content.length + "字）");
            } else {
              that.submitSubComment($input);
              // fbComment.submitComment($input);
            }
          } else {
            that.message("回复内容不能为空");
          }
          return false;
        }
      });
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.userInfo.name = JSON.parse(localStorage.CENTERINFO).name;
      // $.get('<?php echo SHOP_SITE_URL?>/index.php?act=facebook_content&op=get_user', {}, (respon) => {
      //     let res = JSON.parse(respon)
      //     if (res.code == 200) {
      //         this.userInfo = res.data;
      //     } else {
      //         this.message(res.msg);
      //     }
      // });
    },
    // 获取帖子列表
    getArticleList() {
      this.loading = true;
      getArticleList({
        goods_id: this.goods_id,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.dataList = res.data;
          this.dataList.forEach((item) => {
            if (item.image_arr && item.image_arr[0] == "") {
              item.image_arr = [];
            }
          });
        }
      });
      // $.get('<?php echo SHOP_SITE_URL?>/index.php?act=facebook_content&op=get_article_list', {goods_id: this.goods_id}, (respon) => {
      //     let res = JSON.parse(respon)

      //     this.loading = false;
      //     if (res.code == 200) {
      //         this.dataList = res.data;
      //         this.dataList.forEach(item => {
      //             if (item.image_arr && item.image_arr[0] == '') {
      //                 item.image_arr = [];
      //             }
      //         });
      //     } else {
      //         this.message(res.msg);
      //     }
      // });
    },
    // 现在创建
    showCreated() {
      this.addVisible = true;
      this.upFileNames = [];
      this.upFileNamesID = [];
      this.upFiles = [];
      this.addText = "";
    },
    // 发帖
    submit() {
      this.loading = true;
      let params = {
        content: this.addText,
        image: this.upFileNames.join(","),
        file_ids: this.upFileNamesID.join(","),
        goods_id: this.goods_id,
      };
      submitArticle(params).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.message("发帖成功！");
          this.addVisible = false;
          this.getArticleList();
        }
      });

      // $.post('<?php echo SHOP_SITE_URL?>/index.php?act=facebook_content&op=sub_article', params, (respon) => {
      //     this.loading = false;
      //     let res = JSON.parse(respon);
      //     if (res.code == 200) {
      //         this.message('发帖成功！');
      //         this.addVisible = false;
      //         this.getArticleList();
      //     } else {
      //         this.message(res.msg);
      //     }
      // });
    },
    // 图片上传
    onloadFn(e) {

      let file = e.target.files[0];
      let isType = file.type;
      let typeArr = ["image/jpg", "image/png", "image/jpeg", "image/gif"];
      let isLt1M = file.size / 1024 / 1024 < 1;
      if (typeArr.indexOf(isType) === -1) {
        this.message("上传头像图片只能是 jpg png jpeg gif格式!");
        return;
      }
      if (!isLt1M) {
        this.message("上传图片大小不能超过 1MB!");
        return;
      }
      let fileData = new FormData();
      fileData.append("imagelist", file);
      fileData.append("name", "facebook");

      this.loading = true;
      uploadFiles(fileData).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.upFileNames.push(res.data.short_url);
          this.upFileNamesID.push(res.data.id);
          // 显示图片
          var fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            this.upFiles.push(fileReader.result);
          };
        } else {
          this.loading = false;
        }
      });
      // $.ajax({
      //     url: "<?php echo SHOP_SITE_URL?>/index.php?act=facebook_content&op=image_upload",
      //     type: 'POST',
      //     data: fileData,
      //     dataType: 'json',
      //     contentType: false,
      //     processData: false,
      //     success: (res) => {
      //         this.loading = false;
      //         if (res.code == 200) {
      //             this.upFileNames.push(res.data.image);
      //             // 显示图片
      //             var fileReader = new FileReader();
      //             fileReader.readAsDataURL(file);
      //             fileReader.onload = () => {
      //                 this.upFiles.push(fileReader.result);
      //             }
      //         } else {
      //             this.message(res.msg);
      //         }

      //     },
      //     error: (err) => {
      //         this.loading = false;
      //         this.message('网络异常，请稍后再试');
      //     }
      // })
    },
    // 删除上传的图片
    clearUpImg() {
      this.upFiles = [];
      this.upFileNames = [];
    },
    // 图片预览
    showImg(url) {

      this.preImg = url || "";
      this.preShow = true;
    },
    // 删除帖子
    deleteItem(item) {
      this.deleteForm = { article_id: item.article_id };
      this.deleteVisble = true;
    },
    sureDelete() {
      this.loading = true;
      delArticle(this.deleteForm).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.message("删除成功！");
          this.deleteVisble = false;
          this.getArticleList();
        }
      });
      // $.get('<?php echo SHOP_SITE_URL?>/index.php?act=facebook_content&op=del_article', this.deleteForm, (respon) => {
      //     this.loading = false;
      //     let res = JSON.parse(respon);
      //     if (res.code == 200) {
      //         this.message('删除成功！');
      //         this.deleteVisble = false;
      //         this.getArticleList();
      //     } else {
      //         this.message(res.msg);
      //     }
      // })
    },
    // 回复
    submitcomment(item) {
      console.log("item: ", item);
    },
    // 回复他人评论
    submitSubComment($input) {
   
      //提交评论
      if (this.commentSubmitting) {
        this.message("回复中，请耐心等待");
        return false;
      }
      this.commentSubmitting = true;
      var commentContent = $input.val().trim();
      var article_id = $input.attr("article_id"); // 文章id
      var commentId = $input.attr("data-id"); // 评论ID
      var commentPid = $input.attr("data-pid");
      replyContent({
        content: commentContent,
        article_content_id: commentId,
        article_id: article_id,
      }).then((res) => {
        if (res.code == 1) {
          this.message("回复成功");
          this.getArticleList();
          $input.closest(".comment-box").next().find(".comment-empty").remove();
          if (commentPid != 0) {
            $input.closest(".comment-box").addClass("hide");
          }
          $input.val("");
        }
        this.commentSubmitting = false;
      });
      // $.ajax({
      //     url: "<?php echo SHOP_SITE_URL?>/index.php?act=facebook_content&op=reply_content",
      //     type: "post",
      //     data: { content: commentContent, article_content_id: commentId, article_id: article_id },
      //     dataType: 'json',
      //     success: (e) => {
      //         if (e.code == 200) {
      //             this.message('回复成功');
      //             this.getArticleList();
      //             $input.closest('.comment-box').next().find('.comment-empty').remove();
      //             if (commentPid != 0) {
      //                 $input.closest('.comment-box').addClass('hide');
      //             }
      //             $input.val('');
      //         } else {
      //             this.message(e.msg);
      //         }
      //     },
      //     complete: () => {
      //         this.commentSubmitting = false;
      //     }
      // });
    },
    // 查看评论
    showComment(item) {
      if (this.activeCommentID === item.article_id) {
        this.activeCommentID = "";
      } else {
        this.activeCommentID = item.article_id;
      }
    },
    // 提示信息
    message(text, time, noAnimation) {
      var objClass = "page-toast-tip";
      var actionClass = "on";
      var objNode = document.querySelector("." + objClass);
      var creatNode;
      var defaultLastTime = 2000;
      var showPopupDiv = function (
        text,
        hideClassName,
        showClassName,
        noAnimation
      ) {
        var creatNode = document.createElement("div");
        creatNode.className = noAnimation ? showClassName : hideClassName;
        creatNode.textContent = text;
        document.body.appendChild(creatNode);
        noAnimation ||
          setTimeout(function () {
            creatNode.className = showClassName;
          }, 50);
        return creatNode;
      };
      var hidePopupDiv = function (dom, hideClassName, lastTime, noAnimation) {
        setTimeout(function () {
          if (noAnimation) {
            dom.parentNode.removeChild(dom);
          } else {
            dom.className = hideClassName;
            setTimeout(function () {
              dom.parentNode.removeChild(dom);
            }, 200);
          }
        }, lastTime);
      };
      if (!objNode) {
        creatNode = showPopupDiv(
          text,
          objClass,
          objClass + " " + actionClass,
          noAnimation
        );
        if (time === "on") {
          return false;
        } else {
          time = parseInt(time);
          time || (time = defaultLastTime);
          hidePopupDiv(creatNode, objClass, time, noAnimation);
        }
      } else {
        if (time === "off") {
          hidePopupDiv(objNode, objClass, time, noAnimation);
        } else {
          setTimeout(function () {
            if (!document.querySelector("." + objClass)) {
              creatNode = showPopupDiv(
                text,
                objClass,
                objClass + " " + actionClass,
                noAnimation
              );
              if (time === "on") {
                return false;
              } else {
                time = parseInt(time);
                time || (time = defaultLastTime);
                hidePopupDiv(creatNode, objClass, time, noAnimation);
              }
            }
          }, 300);
        }
      }
    },
    getAllUrlParams(url) {
      // 用JS拿到URL，如果函数接收了URL，那就用函数的参数。如果没传参，就使用当前页面的URL
      let queryString = url
        ? url.split("?")[1]
        : window.location.search.slice(1);
      // 用来存储我们所有的参数
      let obj = {};
      // 如果没有传参，返回一个空对象
      if (!queryString) {
        return obj;
      }
      // #后面的东西不是查询字符串的一部分，所以去掉它
      queryString = queryString.split("#")[0];
      // 将参数分成数组
      let arr = queryString.split("&");
      for (let i = 0; i < arr.length; i++) {
        // 分离成key:value的形式
        let a = arr[i].split("=");
        // 将undefined标记为true
        let paramName = a[0];
        let paramValue = typeof a[1] === "undefined" ? true : a[1];
        // 如果调用对象时要求大小写区分，可删除这两行代码
        paramName = paramName.toLowerCase();
        if (typeof paramValue === "string")
          paramValue = paramValue.toLowerCase();
        // 如果paramName以方括号结束, e.g. colors[] or colors[2]
        if (paramName.match(/[(\d+)?]$/)) {
          // 如果paramName不存在，则创建key
          let key = paramName.replace(/[(\d+)?]/, "");
          if (!obj[key]) obj[key] = [];
          // 如果是索引数组 e.g. colors[2]
          if (paramName.match(/[\d+]$/)) {
            // 获取索引值并在对应的位置添加值
            let index = /[(\d+)]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // 如果是其它的类型，也放到数组中
            obj[key].push(paramValue);
          }
        } else {
          // 处理字符串类型
          if (!obj[paramName]) {
            // 如果如果paramName不存在，则创建对象的属性
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === "string") {
            // 如果属性存在，并且是个字符串，那么就转换为数组
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // 如果是其它的类型，还是往数组里丢
            obj[paramName].push(paramValue);
          }
        }
      }
      return obj;
    },
  },
  watch: {
    addVisible(val) {
      if (val) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "initial";
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "./facebook/css/common.css";
</style>
